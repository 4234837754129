<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136.9 170.03"
  >
    <path
      d="M224.06,306.91c14.25-3.06,14.49-21.38,3-49.26,24.75,10.3,29.26,27.84,5.44,51.35,4.37,3.06,4.4,5.41,5.54,11.22-4.11-5.79-6.34-7.31-9.11-8.84a12,12,0,0,0-5.1,7.79c-.12-3.22.43-5.72.49-8.78A47.84,47.84,0,0,0,213.45,317c3.25-6.68,5.8-9.08,11-10.15"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #b0a690;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M235,327"
      transform="translate(-201.9 -154.96)"
      style="
        fill: none;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M244.19,310.28c14.25-3.07,14.5-21.39,3-49.26,24.75,10.3,29.25,27.84,5.44,51.35,4.37,3.06,4.4,5.41,5.53,11.21-3.34-5.74-5.63-7.26-9.11-8.83-4.69,2.45-5.52,4.71-7.32,8.83a24.27,24.27,0,0,1,1.13-9,35.38,35.38,0,0,0-9.3,5.74c3.26-6.68,5.81-9.08,11-10.16"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #b0a690;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M217.81,324.58"
      transform="translate(-201.9 -154.96)"
      style="
        fill: none;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M334.33,281.19c19.58-1.77-33.47,6.4-64.83-7.72s-53.2-33.56-48.76-43.42,33.47-6.4,64.83,7.72S314.74,283,334.33,281.19Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #b29c88;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <ellipse
      cx="243.27"
      cy="245.03"
      rx="31.78"
      ry="47.33"
      transform="translate(-273.16 -52.37) rotate(-20.84)"
      style="
        fill: #fffcdd;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M284.12,220.87c17.74,13.24,40.06,33.82,40.06,33.82-6.59-1.78-59.66,12.71-49.34,17.71,22.57,10.94-42.61-19.59-40-33.82S264.15,206,284.12,220.87Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #e5d3bd;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M286.89,226.52c12.21,16.34,19.92,52.33,29.18,47.79,0,0-32.42,15.2-55.93,2.76S223.57,243.24,231,229.28,274.69,210.17,286.89,226.52Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #eed09d;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M261.76,169.94a22.71,22.71,0,0,1,.51-12.84l-10.45,11.82c-3.47-.14-7.14-.15-10.94-.15-3.5,0-6.89,0-10.12.12l-9.91-11.79a18.78,18.78,0,0,1-1,12.88c-10.48,2.06-17.4,7.7-17.4,23.11,0,24.31,17.23,24.31,38.48,24.31s38.48,0,38.48-24.31C279.36,177.58,272.35,172,261.76,169.94Z"
      transform="translate(-201.9 -154.96)"
      style="fill: #fffcdd; stroke: #c2b59b; stroke-miterlimit: 10"
    />
    <path
      d="M219,189.13c.58,3.64,3.09,5.6,6.9,5.6s6.9-2.51,6.9-5.6-3.09-5.61-6.9-5.61S218.44,185.48,219,189.13Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #efe4ac;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M262.77,189.13c-.59,3.64-3.09,5.6-6.9,5.6s-6.9-2.51-6.9-5.6,3.09-5.61,6.9-5.61S263.36,185.48,262.77,189.13Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #efe4ac;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M219.8,170c-.44,8.38,15,9.93,21.08,23.11"
      transform="translate(-201.9 -154.96)"
      style="fill: none; stroke: #c2b59b; stroke-miterlimit: 10"
    />
    <path
      d="M262,170c.43,8.38-15,9.93-21.09,23.11"
      transform="translate(-201.9 -154.96)"
      style="fill: none; stroke: #c2b59b; stroke-miterlimit: 10"
    />
    <path
      d="M245.52,201.27c0,4.69-4.64,8.49-4.64,8.49s-4.63-3.8-4.63-8.49,2.07-8.5,4.63-8.5S245.52,196.57,245.52,201.27Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #f7d98f;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <ellipse
      cx="53.97"
      cy="33.17"
      rx="2.04"
      ry="3.03"
      style="
        fill: #231f20;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <ellipse
      cx="23.99"
      cy="33.17"
      rx="2.04"
      ry="3.03"
      style="
        fill: #231f20;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M210.46,206.63"
      transform="translate(-201.9 -154.96)"
      style="fill: none; stroke: #c2b59b; stroke-miterlimit: 10"
    />
    <path
      d="M210.46,160.41"
      transform="translate(-201.9 -154.96)"
      style="fill: none; stroke: #c2b59b; stroke-miterlimit: 10"
    />
    <path
      d="M243.27,199.61c0,2.72-2.39,4.92-2.39,4.92s-2.39-2.2-2.39-4.92,1.07-4.92,2.39-4.92S243.27,196.89,243.27,199.61Z"
      transform="translate(-201.9 -154.96)"
      style="
        fill: #d7b262;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M267.57,298.35"
      transform="translate(-201.9 -154.96)"
      style="
        fill: none;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M248.34,269.44"
      transform="translate(-201.9 -154.96)"
      style="
        fill: none;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M250.43,296"
      transform="translate(-201.9 -154.96)"
      style="
        fill: none;
        stroke: #c2b59b;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
  </svg>
</template>

<script>
export default {
  name: "AnimalB",
};
</script>

<style lang="scss" module></style>
