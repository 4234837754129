<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 234.71 307.8"
  >
    <ellipse
      cx="139.09"
      cy="210.89"
      rx="68.63"
      ry="56.16"
      style="fill: none; stroke: #414042; stroke-miterlimit: 10"
    />
    <ellipse
      cx="294.74"
      cy="389.96"
      rx="17.12"
      ry="42.78"
      transform="translate(-146.96 -115.74) rotate(-4)"
      style="fill: #d1d3d4; stroke: #414042; stroke-miterlimit: 10"
    />
    <ellipse
      cx="227.19"
      cy="390.64"
      rx="42.33"
      ry="17.12"
      transform="matrix(0.07, -1, 1, 0.07, -298.82, 452.78)"
      style="fill: #d1d3d4; stroke: #414042; stroke-miterlimit: 10"
    />
    <path
      d="M319.65,321.65c0,40.85-60.08,74-60.08,74s-60.08-33.11-60.08-74,26.9-74,60.08-74S319.65,280.81,319.65,321.65Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #e6e7e8; stroke: #414042; stroke-miterlimit: 10"
    />
    <path
      d="M297.28,312.82c0,27.92-37.71,50.56-37.71,50.56s-37.71-22.64-37.71-50.56,16.88-50.56,37.71-50.56S297.28,284.89,297.28,312.82Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #809599; stroke: #414042; stroke-miterlimit: 10"
    />
    <path
      d="M259.27,172.42c-39.52,0-61.65,55.69-59,72.27,2.27,16.58,29.32,39.1,59,39.1s57.18-27.31,59.12-40.48C320.7,230.13,298.84,172.42,259.27,172.42Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #d1d3d4"
    />
    <path
      d="M259.27,172.42c-39.52,0-61.65,55.69-59,72.27,2.27,16.58,29.32,39.1,59,39.1s57.18-27.31,59.12-40.48C320.7,230.13,298.84,172.42,259.27,172.42Z"
      transform="translate(-120.48 -137.25)"
      style="fill: none; stroke: #6d6e71; stroke-miterlimit: 10"
    />
    <ellipse
      cx="139.09"
      cy="136.52"
      rx="13.36"
      ry="10.02"
      style="fill: #335c64; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M267.46,273.76c0,5.54-3.66,10-8.19,10s-8.19-4.49-8.19-10,8.19-10,8.19-10S267.46,268.23,267.46,273.76Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #0a3542; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M222.49,227.23a15.48,15.48,0,0,0,11.85,8.32c7.8,1.12,14.71-2.05,12.56-6.46a15,15,0,0,0-11.84-8.31C229,220,220.41,223.35,222.49,227.23Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #d6c2ad; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M247,174.13c-9.08-14.58-26.21-37.42-35-36.35-10.46,1.28,3.55,61.53,3.55,61.53"
      transform="translate(-120.48 -137.25)"
      style="fill: #d1d3d4; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M302.26,199.84S325.31,140.68,315,138c-9.51-2.51-33,22.89-43.16,36.06"
      transform="translate(-120.48 -137.25)"
      style="fill: #d1d3d4; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M298.54,195.09s16-44.17,8.86-46.21c-6.62-1.87-23,17.1-30.06,26.93"
      transform="translate(-120.48 -137.25)"
      style="fill: #7e6b71; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M242.32,175.57c-6.64-11.78-19.56-31.06-26-30.19-7.66,1,2.59,49.71,2.59,49.71"
      transform="translate(-120.48 -137.25)"
      style="fill: #7e6b71; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M296.81,227.23A15.48,15.48,0,0,1,285,235.55a21.38,21.38,0,0,1-3.95.21,13.27,13.27,0,0,1-7.28-2.11,4.35,4.35,0,0,1-1.44-1.68,3.23,3.23,0,0,1,.11-2.88,14.92,14.92,0,0,1,9.86-7.94,15.09,15.09,0,0,1,2-.38C290.33,220,298.89,223.35,296.81,227.23Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #d6c2ad; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M288.68,228.18c0,3.69-3.15,6.76-7.26,7.27a8.69,8.69,0,0,1-1.26.09,9.16,9.16,0,0,1-6-2.16,7.19,7.19,0,0,1-1.44-1.64,3.1,3.1,0,0,1,.11-2.82,14.87,14.87,0,0,1,9.86-7.77C286.16,222.07,288.68,224.87,288.68,228.18Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #231f20; stroke: #231f20; stroke-miterlimit: 10"
    />
    <path
      d="M231.23,228.51c0,3.69,3.15,6.75,7.26,7.26a7.48,7.48,0,0,0,1.26.1,9.16,9.16,0,0,0,6-2.16,7.24,7.24,0,0,0,1.44-1.65,3.1,3.1,0,0,0-.11-2.82,14.85,14.85,0,0,0-9.86-7.76C233.75,222.39,231.23,225.19,231.23,228.51Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #231f20; stroke: #231f20; stroke-miterlimit: 10"
    />
    <ellipse
      cx="238.47"
      cy="229.34"
      rx="6.08"
      ry="1.93"
      transform="translate(-118.74 323.23) rotate(-88.2)"
      style="
        fill: #f6e8d7;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <ellipse
      cx="281.48"
      cy="229.34"
      rx="1.93"
      ry="6.08"
      transform="translate(-127.56 -128.28) rotate(-1.8)"
      style="
        fill: #f6e8d7;
        stroke: #231f20;
        stroke-miterlimit: 10;
        stroke-width: 0.5px;
      "
    />
    <path
      d="M319.65,321.65c-9.3,95.83-82,101.36-196.75,47C280.85,525.62,422.7,403.24,319.65,321.65Z"
      transform="translate(-120.48 -137.25)"
      style="fill: #d1d3d4; stroke: #414042; stroke-miterlimit: 10"
    />
    <path
      d="M259.57,263.74q2.56-53-44-64.43"
      transform="translate(-120.48 -137.25)"
      style="fill: none; stroke: #414042; stroke-miterlimit: 10"
    />
    <path
      d="M259.57,263.74c1.24-36.13,13.8-59.34,42.69-63.9"
      transform="translate(-120.48 -137.25)"
      style="fill: none; stroke: #414042; stroke-miterlimit: 10"
    />
  </svg>
</template>

<script>
export default {
  name: "AnimalA",
};
</script>

<style lang="scss" module></style>
